'use strict';

angular.module('cpformplastApp.deliveries')
    .controller('DeliveryManagementController', function($scope, DataManager, $state, Notification) {

  $scope.deliveries = [];

  $scope.initialSort = "bol";
  $scope.sortDesc = true;
  $scope.defaultPageNumber = 3
  $scope.limit = 20;
  $scope.skip = 0;
  $scope.endpoint = DataManager.fetchActualDeliveries

  $scope.deliveryTableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': () => {
      $state.go('deliveries/delivery-management/delivery-creation',{'deliveryId' : ''});
    }
  }];

  $scope.deliveryTableStructure = [{
    'title':'Transporteur',
    'value':'shipper',
    'filter':'alpha'
  },{
    'title': 'Client',
    'value': 'client',
    'filter': 'alpha'
  },{
    'title': '#Job',
    'value': 'job',
    'filter': 'alpha'
  },{
    'title':'#Booking',
    'value':'booking',
    'filter':'alpha'
  },{
    'title':'#BOL',
    'value':'bol',
    'filter':'alpha'
  },{
    'title':'Date de livraison',
    'value':'date',
    'filter':'date'
  },{
    'title':'Période',
    'value':'timeOfDay',
    'filter':'alpha'
  },{
    'title':'Details',
    'value':'details',
    'filter':'alpha'
  }];

/*
  const fetchAndFormatDeliveries = function (limit, skip) {
    DataManager.fetchActualDeliveries("", $scope.initialSort, "desc", limit, skip).then((data) => {
      $scope.deliveries = data;
      for (var i = 0; i < $scope.deliveries.length; i++) {
        $scope.deliveries[i].date = new Date($scope.deliveries[i].date);
        $scope.deliveries[i].client = $scope.deliveries[i].job.item.client.name;
        $scope.deliveries[i].job = $scope.deliveries[i].job.number;
      }
    })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });
  }
  $scope.init = function() {
    fetchAndFormatDeliveries($scope.limit, $scope.skip);
  };
*/

  $scope.onDataChange =  function(data) {
    $scope.deliveries = data;
    for (var i = 0; i < $scope.deliveries.length; i++) {
      $scope.deliveries[i].date = new Date($scope.deliveries[i].date);
      $scope.deliveries[i].client = $scope.deliveries[i].job.item.client.name;
      $scope.deliveries[i].job = $scope.deliveries[i].job.number;
    }

  }

  $scope.selectDelivery = function(delivery) {
    $state.go('deliveries/delivery-management/delivery-creation', {'deliveryId': delivery._id});
  };

  $scope.init();

});
